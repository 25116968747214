<template>
  <div>
    <NavBar/>
    <div class="container mt-4 mb-4">
      <div class="row">
        <!-- Colonne de gauche avec l'image -->
        <!-- Colonne de droite avec l'accordéon -->
        <div class="col-md-6">
          <div class="mb-4 mt-4">
            <img :src="selectedArrondissement.image" class="img-fluid" alt="Image de l'arrondissement"/>
          </div>
        </div>
        <div class=" align-self-center col-md-6">
          <div v-if="selectedArrondissement">
            <h1 class="text-center mb-5">{{ selectedArrondissement.nom }}</h1>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item" v-if="selectedArrondissement.détail">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true" aria-controls="collapseOne">
                    L'arrondissement en détail
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    {{ selectedArrondissement.détail }}
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-if="selectedArrondissement.environnement">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Environnement
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    {{ selectedArrondissement.environnement }}
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-if="selectedArrondissement.monuments">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Monuments
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                     data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    {{ selectedArrondissement.monuments }}
                  </div>
                </div>
              </div>
              <div v-if="selectedArrondissement">
                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item" v-if="selectedArrondissement.monuments2">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Monuments
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                         data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        {{ selectedArrondissement.monuments2 }}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" v-if="selectedArrondissement.espaces">
                    <h2 class="accordion-header" id="headingFive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Espaces
                      </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                         data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        {{ selectedArrondissement.espaces }}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" v-if="selectedArrondissement.conclusion">
                    <h2 class="accordion-header" id="headingSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        Pour finir
                      </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingThree"
                         data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        {{ selectedArrondissement.conclusion }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LogementByArrondissement/>
    <Zone/>
    <Footer/>
  </div>
</template>


<script>
import Footer from "@/components/Footer";
import NavBar from "@/components/Navbar";
import Zone from "@/components/Zone";
import LogementByArrondissement from "@/components/Arrondissements/LogementsByArrondissement";

export default {
  name: "ArrondissementPage",
  components: {LogementByArrondissement, Zone, Footer, NavBar},
  data() {
    return {
      arrondissements: [
        {
          id: 1,
          nom: "1er Arrondissement",
          détail: "Le premier arrondissement de Paris est le plus petit en termes de superficie, mais il se distingue " +
              "par sa faible densité de population. Il constitue le cœur historique de la ville, abritant des trésors tels que " +
              "le Musée du Louvre, sa célèbre Pyramide, et le jardin des Tuileries. De plus, il englobe l'un des quartiers les plus" +
              " anciens de Paris, le quartier des Halles.",
          environnement: "Cette zone est délimitée au sud par la Seine et est reliée à la rive gauche par six ponts " +
              "emblématiques, notamment le pont au Change, le pont Neuf (le plus ancien de Paris), le pont des Arts " +
              "(connu pour les cadenas d'amoureux), le pont du Carrousel, le pont Royal et la nouvelle passerelle Léopold-Sédar-Senghor.",
          monuments: "Il regorge de monuments historiques, notamment l'Arc de Triomphe du Carrousel, la Colonne Vendôme," +
              " la Colonne Médicis, la Conciergerie, le Forum des Halles et la Samaritaine. De nombreuses églises remarquables," +
              " telles que Notre-Dame-de-l'Assomption, Saint-Eustache, Saint-Germain-l'Auxerrois, Saint-Roch, La Sainte-Chapelle" +
              " et le Temple protestant de l'Oratoire du Louvre, y sont également présentes.",
          monuments2: "En outre, cet arrondissement abrite des bâtiments civils d'intérêt, notamment la Bourse de commerce de " +
              "Paris, l'Hôtel des Postes, le Palais de justice de Paris, le Ministère de la Justice, le Palais Cambon (siège de la" +
              " Cour des comptes), le Palais-Royal (siège du Conseil constitutionnel, du Conseil d'État et du Ministère de la Culture)" +
              " et l'Hôtel de Toulouse (siège de la Banque de France).",
          espaces: "Malgré sa petite taille, le premier arrondissement bénéficie du plus grand nombre d'espaces verts de" +
              " Paris, en dehors du Bois de Boulogne et du Bois de Vincennes. Parmi ces espaces, on compte le Jardin des Halles," +
              " le Jardin de l'Infante, le Jardin de l'Oratoire, le Jardin du Palais-Royal, le Square du Vert-Galant et le Square " +
              "de la Place-Dauphine.",
          conclusion: "En raison de son attrait touristique, le premier arrondissement compte peu d'hôtels bon marché. " +
              "Les points d'intérêt incontournables comprennent le Musée du Louvre, la Pyramide du Louvre, le Jardin des " +
              "Tuileries, la Colonne Vendôme, la Conciergerie, la Samaritaine et la Sainte Chapelle.",
          image: "/arrondissementsdetails/vendome2.jpg",
        },
        {
          id: 2,
          nom: "2ème Arrondissement",
          détail: "Le plus petit arrondissement de Paris, La Bourse, offre peu d'attractions exceptionnelles, à " +
              "l'exception du Palais Brongniart, du Palais Berlitz et de la Basilique Notre-Dame-des-Victoires. Vous pouvez " +
              "également découvrir le Centorial, un magnifique bâtiment rue du Quatre-Septembre, ancien siège du Crédit Lyonnais." +
              " La Bourse abrite l'Agence France-Presse et l'Autorité des marchés financiers.",
          environnement: "En revanche, le deuxième arrondissement est un paradis pour les amateurs de théâtre et de cinéma, " +
              "avec six salles de théâtre (dont le Théâtre Daunou, le Théâtre des Bouffes-Parisiens, et le Théâtre des Variétés) " +
              "et deux cinémas de renom, le Grand Rex et le Gaumont-Opéra.",
          image: "/arrondissementsdetails/rex2.jpg",
        },
        {
          id: 3,
          nom: "3ème Arrondissement",
          détail: "Le 3ème arrondissement de Paris, également connu sous le nom de \"Le Marais\", est un quartier" +
              " historique et branché de la ville. Il est célèbre pour ses ruelles pittoresques, ses hôtels particuliers " +
              "du XVIIe siècle, ses musées fascinants comme le Musée Picasso, et ses boutiques de mode tendance. Le Marais" +
              " est aussi un lieu de vie nocturne animée avec de nombreux restaurants, bars et galeries d'art. C'est un mélange " +
              "captivant d'histoire et de modernité qui attire les visiteurs du monde entier.",
          image: "/arrondissementsdetails/paris3-2.jpg",
        },
        {
          id: 4,
          nom: "4ème Arrondissement",
          détail: "Le 4ème arrondissement de Paris est un joyau historique et culturel au cœur de la ville. " +
              "On y trouve des sites emblématiques comme l'Hôtel de Ville, la Place des Vosges, la Cathédrale Notre-Dame, " +
              "et le Centre Pompidou. Le quartier est également un centre administratif et religieux, avec divers lieux de" +
              " culte, notamment des églises, synagogues, et un monument commémoratif de la Shoah. Les amateurs d'art peuvent " +
              "visiter le Centre Pompidou pour découvrir des œuvres d'artistes modernes. Une balade à pied vous permettra d'explorer" +
              " le Marais, la Place des Vosges, le musée d'Art Moderne, et d'admirer Notre-Dame depuis l'île Saint-Louis.",
          image: "/arrondissementsdetails/bastille2.jpg",
        },
        {
          id: 5,
          nom: "5ème Arrondissement",
          détail: "Le 5ème arrondissement de Paris, également connu sous le nom de \"Quartier Latin\", est un quartier " +
              "emblématique de la ville. Il est réputé pour sa riche histoire intellectuelle et académique, en grande partie" +
              " grâce à la présence de la Sorbonne, l'une des plus anciennes universités du monde. Le quartier regorge de librairies," +
              " de cafés et de bouquinistes, ce qui en fait un lieu de prédilection pour les amoureux de la littérature et de " +
              "la philosophie. Vous y trouverez également le majestueux Panthéon, où reposent de nombreuses personnalités historiques" +
              " françaises. Le 5ème arrondissement est également célèbre pour ses rues sinueuses, ses places animées, comme la Place " +
              "de la Contrescarpe, et ses charmants jardins, notamment le Jardin des Plantes. C'est un quartier qui incarne à la fois" +
              " l'esprit académique et le charme bohème de Paris.",
          image: "/arrondissementsdetails/pantheon2.jpg",
        },
        {
          id: 6,
          nom: "6ème Arrondissement",
          détail: "Le 6ème arrondissement de Paris, situé sur la Rive Gauche de la Seine, est un quartier élégant " +
              "et culturellement riche. Il abrite le légendaire quartier de Saint-Germain-des-Prés, qui a été le cœur de " +
              "la vie intellectuelle et artistique de Paris depuis des décennies. Vous y trouverez de célèbres cafés " +
              "littéraires comme le Café de Flore et les Deux Magots, qui ont accueilli des écrivains et des philosophes" +
              " célèbres. Le Jardin du Luxembourg, un parc splendide, est un lieu de détente prisé des Parisiens et des " +
              "visiteurs. Le 6ème arrondissement est également le berceau de l'Académie française et abrite de nombreuses " +
              "galeries d'art, boutiques de luxe et institutions culturelles. C'est un quartier qui respire l'élégance et " +
              "la culture à chaque coin de rue.",
          image: "/arrondissementsdetails/luxembourg2.jpg",
        },
        {
          id: 7,
          nom: "7ème Arrondissement",
          détail: "Le 7e arrondissement de Paris, parmi les quartiers les plus élégants," +
              " regroupe des institutions gouvernementales, l'Assemblée Nationale, l'Hôtel des Invalides" +
              " et abrite des ambassades. Ses larges avenues et espaces incluent le Champ de Mars. La Tour " +
              "Eiffel domine la région, et on y trouve également les Musées Rodin, du Quai Branly et le Musée d'Orsay.",
          image: "/arrondissementsdetails/eiffel2.jpg",
        },
        {
          id: 8,
          nom: "8ème Arrondissement",
          détail: "Le 8e arrondissement de Paris, riche en attractions touristiques, offre également une profusion " +
              "de commerces et d'hôtels de luxe. L'Arc de Triomphe trône au centre de la Place de l'Étoile, offrant une vue" +
              " magnifique sur Paris depuis son sommet. D'autres monuments notables incluent l'Obélisque de la Concorde, " +
              "le Grand Palais, le Palais de la Découverte et l'impressionnante Église de la Madeleine. Les Champs-Élysées, " +
              "l'avenue la plus célèbre du monde, abritent les plus grandes marques de luxe, tout comme les prestigieuses " +
              "Avenue Montaigne et Rue du Faubourg-Saint-Honoré. Parmi les quatre des sept palaces parisiens que l'on y " +
              "trouve figurent le Bristol, le Crillon, le George V et le Plaza Athénée. Le charmant Parc Monceau constitue" +
              " le poumon vert de ce quartier. En outre, la vie culturelle y est florissante, avec de nombreux théâtres tels " +
              "que le Théâtre des Champs-Élysées, le Théâtre du Rond-Point, le Théâtre Marigny, le Théâtre de la Madeleine, " +
              "les Mathurins, ainsi que les célèbres cabarets du Lido et du Crazy Horse.",
          image: "/arrondissementsdetails/pont2.jpg",
        },
        {
          id: 9,
          nom: "9ème Arrondissement",
          détail: "Le 9e arrondissement de Paris est marqué par ses grands boulevards, où la vie culturelle " +
              "s'unit aux grands magasins. L'Opéra Garnier, le plus splendide opéra de Paris et de France, y est situé. " +
              "Les Grands Boulevards abritent les Nouvelles Galeries et les Galeries Lafayette, attirant à la fois les" +
              " Parisiens et les voyageurs du monde entier. Une visite à la terrasse des Galeries Lafayette pour prendre" +
              " un verre est recommandée, offrant une vue magnifique sur Paris. À l'approche de Noël, les vitrines superbement " +
              "décorées sont une attraction prisée. L'Hôtel Drouot, la principale salle de ventes de Paris, est une autre curiosité" +
              "de cet arrondissement, attirant à la fois des experts et des amateurs à la recherche de bonnes affaires. Vous pouvez " +
              "également faire un détour par le Musée de la Vie Romantique, et en été, profiter de son charmant jardin pour un déjeuner ou un verre.",
          image: "/arrondissementsdetails/opera2.jpg",
        },
        {
          id: 10,
          nom: "10ème Arrondissement",
          détail: "Le 10e arrondissement de Paris est un quartier cosmopolite et vivant, se distinguant de " +
              "ses voisins par ses deux grandes gares, la Gare de l'Est et la Gare du Nord.",
          environnement: "Le 11e arrondissement de Paris, situé à l'est de la ville, comprend les quartiers de la Folie" +
              " Méricourt, de Saint Ambroise, de la Roquette et de Saint Marguerite. Bien qu'il ne soit pas une destination" +
              " touristique majeure, il offre un intérêt particulier grâce à sa vie nocturne animée, marquée par des théâtres," +
              " des salles de spectacles, des concerts, des b1ars et des restaurants.",
          monuments: "Parmi les lieux notables, citons le Théâtre de la Bastille, le théâtre de la Main d'Or, le Bataclan, " +
              "le Nouveau Casino, la Salle Olympe-de-Goujes, le Café de la Danse, la Scène Bastille, le Réservoir, le Gybus, " +
              "le Cirque d'Hiver, et bien d'autres.\n" +
              "Il est également intéressant de découvrir les trois grandes places de l'arrondissement : la Place de la Bastille, " +
              "qui symbolise la Révolution française avec sa Colonne de Juillet au centre ; la Place de la Nation, ornée des Colonnes " +
              "Ledoux et de la statue du " +
              "Triomphe de la République ; enfin, la majestueuse Place de la République, un lieu de rassemblement citoyen, " +
              "avec la Statue de la République qui lui a donné son nom en 1883.\n",
          monuments2: "N'oubliez pas de visiter les impressionnantes Porte Saint-Denis et Porte Saint-Martin, qui " +
              "marquaient autrefois la limite de Paris au 17e siècle. Le marché couvert Saint Quentin, situé rue de Magenta, " +
              "est un endroit idéal pour trouver des produits frais de qualité.\n" +
              "Le 10e arrondissement est également dynamique sur le plan culturel, avec de nombreux " +
              "théâtres tels que le Théâtre Saint Antoine, le Théâtre des Bouffes Parisiennes, le Théâtre du Gymnase et " +
              "le Théâtre de la Renaissance.\n",
          conclusion: "Enfin, une curiosité à ne pas manquer est la maison la plus étroite de Paris, située au 39 rue du Château " +
              "d'Eau, mesurant seulement 1,10 m de large. Pour les amateurs de sensations fortes, une visite au Manoir de Paris, " +
              "une maison hantée, promet des frissons garantis.",
          image: "/arrondissementsdetails/canal2.jpg",
        },
        {
          id: 11,
          nom: "11ème Arrondissement",
          détail: "Le 11e arrondissement de Paris, situé à l'est de la ville, comprend les quartiers de la Folie " +
              "Méricourt, de Saint Ambroise, de la Roquette et de Saint Marguerite. Bien qu'il ne soit pas une destination " +
              "touristique majeure, il offre un intérêt particulier grâce à sa vie nocturne animée, marquée par des théâtres," +
              " des salles de spectacles, des concerts, des bars et des restaurants.",
          environnement: "Parmi les lieux notables, citons le Théâtre de la Bastille, le théâtre de la Main d'Or, " +
              "le Bataclan, le Nouveau Casino, la Salle Olympe-de-Goujes, le Café de la Danse, la Scène Bastille, " +
              "le Réservoir, le Gybus, le Cirque d'Hiver, et bien d'autres.",
          monuments: "Il est également intéressant de découvrir les trois grandes places de l'arrondissement : " +
              "la Place de la Bastille, qui symbolise la Révolution française avec sa Colonne de Juillet au centre ; " +
              "la Place de la Nation, ornée des Colonnes Ledoux et de la statue du Triomphe de la République ; enfin, " +
              "la majestueuse Place de la République, un lieu de rassemblement citoyen, avec la Statue de la République " +
              "qui lui a donné son nom en 1883.",
          espaces: "Le 11e arrondissement est réputé pour ses sorties nocturnes, notamment autour de Bastille, " +
              "de la rue de la Roquette, de la rue de Lappe et dans le quartier d'Oberkampf.",
          monuments2: "Le 11e arrondissement est également dynamique sur le plan culturel, avec de nombreux " +
              "théâtres tels que le Théâtre Saint Antoine, le Théâtre des Bouffes Parisiennes, le Théâtre du Gymnase " +
              "et le Théâtre de la Renaissance. Enfin, une curiosité à ne pas manquer est la maison la plus étroite" +
              "de Paris, située au 39 rue du Château d'Eau, mesurant seulement 1,10 m de large. Pour les amateurs de " +
              "sensations fortes, une visite au Manoir de Paris, une maison hantée, promet des frissons garantis.",
          image: "/arrondissementsdetails/paris11-2.jpg",
        },
        {
          id: 12,
          nom: "12ème Arrondissement",
          détail: "Le 12e arrondissement de Paris s'étend à l'est de la ville, bordant la Seine et se prolongeant " +
              "jusqu'au Bois de Vincennes. Il englobe les quartiers de Belair, Picpus, Bercy et des Quinze-Vingts. Bien " +
              "qu'il ne soit pas une destination touristique majeure, le 12e arrondissement offre tout de même quelques " +
              "points d'intérêt.",
          environnement: "La Coulée Verte René Dumont, une voie piétonne qui commence à la Place de la Bastille et suit " +
              "l'ancienne voie ferrée Bastille-Vincennes jusqu'au Bois de Vincennes, en est un exemple. Sous la Coulée " +
              "Verte se trouve le Viaduc des Arts, abritant des artisans et des galeries d'art. Côté divertissement, " +
              "on y trouve l'Accor Hôtel Arena, anciennement Paris Omnisport de Paris Bercy (POPB), la plus grande salle " +
              "de concert intra-muros de Paris, ainsi que l'Opéra Bastille.",
          monuments: "Bercy Village, un quartier réhabilité en 2000, abrite le Cours Saint Emilion, où d'anciens " +
              "entrepôts de Bercy ont été transformés en boutiques, restaurants et cinéma. Une curiosité à découvrir " +
              "est le port de l'Arsenal de Paris, le port de plaisance de la capitale, qui relie la Seine au Canal " +
              "Saint-Martin. En été, c'est un endroit agréable pour se promener et pique-niquer sur ses pelouses.",
          monuments2: "Le 12e arrondissement est traversé par plusieurs ponts qui enjambent la Seine, notamment le " +
              "Pont Amont, le Pont National, le Pont de Tolbiac, la Passerelle Simone-de-Beauvoir, le Pont de Bercy, " +
              "le Pont Charles-de-Gaulle, le viaduc d'Austerlitz et le Pont d'Austerlitz.",
          espaces: "Enfin, le Bois de Vincennes, le plus grand espace vert de Paris, abrite un zoo, le parc " +
              "zoologique de Paris, ainsi que le parc Floral.",
          image: "/arrondissementsdetails/bercy2.jpg",
        },
        {
          id: 13,
          nom: "13ème Arrondissement",
          détail: "Le 13e arrondissement de Paris, à l'exception de ses quais le long de la Seine, n'est pas " +
              "très prisé par les touristes. Ce quartier, autrefois ouvrier, est constitué de plusieurs zones distinctes " +
              ": le quartier chinois, la Butte aux Cailles, les Gobelins et Paris Rive Gauche, avec la Place d'Italie " +
              "comme point central.",
          environnement: "Le quartier de la Butte aux Cailles a préservé le charme d'un petit village d'antan, avec ses " +
              "rues pavées, ses cafés, restaurants et petits commerces de quartier. En revanche, le quartier chinois " +
              "offre une ambiance totalement différente, avec ses enseignes chinoises, ses grossistes, ainsi que " +
              "d'excellents restaurants asiatiques. Le long des quais de la Seine, vous trouverez le nouveau quartier " +
              "de Paris Rive Gauche, qui abrite la Bibliothèque Nationale François Mitterrand (BNF), dont les quatre " +
              "tours évoquent des livres ouverts. Ce quartier a fait l'objet d'une réhabilitation pour moderniser ses " +
              "infrastructures.",
          monuments: "On y trouve également l'Université Paris Diderot, la Gare d'Austerlitz, la Cité de la Mode et " +
              "du Design, l'Institut des Langues et Civilisations Orientales, ainsi que les quais de la Seine, où se " +
              "trouve notamment la Piscine Joséphine Baker.",
          image: "/arrondissementsdetails/bnf2.jpg",
        },
        {
          id: 14,
          nom: "14ème Arrondissement",
          détail: "Le 14e arrondissement de Paris est principalement résidentiel et est situé à l'extrême sud de " +
              "la ville. Il se distingue par une vie culturelle dynamique, avec de nombreux cinémas, théâtres et salles " +
              "de spectacles.",
          environnement: "Une attraction incontournable dans cet arrondissement est les Catacombes de Paris, un ossuaire" +
              " municipal qui abrite les restes de milliers de Parisiens dans d'anciennes carrières.",
          monuments: "La communauté bretonne de Paris s'est rassemblée autour de la Tour Montparnasse, où l'on " +
              "peut déguster d'excellentes crêpes bretonnes. Un autre lieu culturel d'intérêt est la Fondation Cartier" +
              " pour l'art contemporain, qui présente des expositions variées mettant en avant des artistes du monde " +
              "entier. Vous pouvez également apprécier une promenade dans le Parc Montsouris, un jardin de style anglais " +
              "s'étendant sur 15 hectares.",
          image: "/arrondissementsdetails/orsay2.jpg",
        },
        {
          id: 15,
          nom: "15ème Arrondissement",
          détail: "Le 15ème arrondissement de Paris, situé sur la Rive Gauche de la Seine, est le plus peuplé " +
              "de la ville. Il abrite la Tour Montparnasse, le plus haut gratte-ciel de Paris avec 209 mètres de hauteur, " +
              "offrant une vue panoramique incroyable depuis sa terrasse. Le restaurant Ciel de Paris vous permet de dîner " +
              "avec une vue à 360°.",
          environnement: "Vous pouvez également vous promener dans le Parc André-Citroën, un jardin atypique de 24 hectares " +
              "avec pelouses, végétation luxuriante, jeux d'eau, bambouseraies, et un grand ballon captif 'Air Paris' pour" +
              " admirer la ville à 150 mètres de haut.",
          monuments: "L'arrondissement est bordé par la Seine et est relié au XVIème arrondissement par plusieurs " +
              "ponts, notamment le Pont de Bir-Hakeim et le Pont Mirabeau. Enfin, le Paris Expo Porte de Versailles " +
              "accueille de nombreuses expositions et foires qui attirent des milliers de visiteurs.",
          image: "/arrondissementsdetails/montparnasse2.jpg",
        },
        {
          id: 16,
          nom: "16ème Arrondissement",
          détail: "Situé sur la rive droite de la Seine, le 16ème arrondissement de Paris est principalement " +
              "résidentiel mais attire également des touristes grâce à ses musées et sites remarquables tels que le " +
              "Trocadéro et le Bois de Boulogne. Il est connu pour son caractère bourgeois et abrite de nombreuses " +
              "ambassades, consulats et ministères.",
          environnement: "L'arrondissement regorge de trésors culturels et historiques, notamment le jardin du Trocadéro" +
              " avec sa vue imprenable sur la Tour Eiffel, le Palais de Tokyo, le Palais de Chaillot (abritant le Musée " +
              "de l'Architecture et du Patrimoine et le Musée de l'Homme), ainsi que l'Aquarium de Paris.",
          monuments: "Sur le plan sportif, le quartier compte le Parc des Princes, le stade Jean Bouin, le stade " +
              "Roland Garros, ainsi que les hippodromes d'Auteuil et de Longchamp.",
          espaces: "Le Bois de Boulogne, le poumon vert de l'ouest parisien, s'étend sur 846 hectares et comprend" +
              " le Parc de Bagatelle, le Parc du Pré Catalan, le Jardin d'Acclimatation (un parc d'attractions), " +
              "la Fondation Louis Vuitton et le jardin des Serres d'Auteuil.",
          image: "/arrondissementsdetails/arcdetriomphe2.jpg",
        },
        {
          id: 17,
          nom: "17ème Arrondissement",
          détail: "Le 17ème arrondissement de Paris, situé dans la partie nord-ouest de la ville, englobe " +
              "les quartiers de Ternes, des Batignolles, des Epinettes et de la Plaine Monceau. Principalement " +
              "résidentiel, il connaît une transformation partielle dans sa partie nord avec le développement du " +
              "quartier Clichy Batignolles, comprenant le nouveau Palais de Justice et la construction de plus de " +
              "3400 nouveaux logements sur d'anciens terrains de la SNCF.",
          environnement: "Le Parc Martin Luther King, couvrant 6,5 hectares, est également inclus dans ce projet, " +
              "offrant des espaces verts et sportifs. À proximité, le square des Batignolles invite à la détente. " +
              "Bien que cet arrondissement ne compte que peu de sites touristiques majeurs, il propose de nombreux " +
              "bars, restaurants et quelques théâtres pour se divertir.",
          image: "/arrondissementsdetails/champs2.jpg",
        },
        {
          id: 18,
          nom: "18ème Arrondissement",
          détail: "Le 18ème arrondissement de Paris comprend les quartiers célèbres de Montmartre, Pigalle," +
              " Clignancourt, la Chapelle et la Goutte d'Or. Montmartre est une attraction touristique majeure avec " +
              "le Moulin Rouge, la Basilique du Sacré Cœur, la Place du Tertre et d'autres sites incontournables. " +
              "Le cimetière de Montmartre abrite de nombreux artistes français.",
          environnement: "Pigalle, autrefois un quartier chaud, est maintenant un endroit branché, en particulier " +
              "dans la partie sud surnommée 'South Pigalle'. Clignancourt connaît une transformation avec l'arrivée " +
              "de nouveaux bars et restaurants de qualité. Les quartiers de la Chapelle et de la Goutte d'Or ont " +
              "moins d'attractions touristiques, mais le cinéma du Louxor est un point d'intérêt. L'arrondissement " +
              "compte de nombreux théâtres et salles de concerts. C'est un excellent choix pour séjourner à Paris " +
              "en raison de l'offre d'hôtels abordables et de son caractère atypique.",
          image: "/arrondissementsdetails/montmartre2.jpg",

        },
        {
          id: 19,
          nom: "Petite couronne de Paris",
          espaces: "La Petite Couronne de Paris est bénie par une variété d'espaces verts et de lieux culturels. " +
              "Parmi eux, le Bois de Boulogne, un vaste poumon vert de 846 hectares, abrite le Parc de Bagatelle," +
              " le Parc du Pré Catalan, le Jardin d'Acclimatation (un parc d'attractions divertissant), la Fondation " +
              "Louis Vuitton, et le paisible jardin des Serres d'Auteuil. Ces espaces offrent des échappées bienvenues " +
              "de l'agitation urbaine, où la nature et l'art se rencontrent harmonieusement.",
          environnement: "La Petite Couronne de Paris regorge d'une diversité de quartiers dynamiques. " +
              "Pigalle, jadis connu comme un quartier chaud, a subi une transformation majeure pour devenir " +
              "un lieu branché, en particulier dans sa partie sud, souvent surnommée \"South Pigalle\". Clignancourt " +
              "est en train de se réinventer avec l'arrivée de nouveaux bars et restaurants de grande qualité, faisant" +
              " de ce quartier un endroit à découvrir. Les quartiers de la Chapelle et de la Goutte d'Or sont moins fréquentés" +
              " par les touristes, mais le cinéma du Louxor ajoute une note culturelle à ces quartiers. Vous trouverez " +
              "également de nombreux théâtres et salles de concerts dans cette région, en faisant un excellent choix pour " +
              "séjourner à Paris en raison de l'offre d'hôtels abordables et de son caractère atypique. La Petite Couronne" +
              " est un véritable trésor caché pour les voyageurs en quête d'une expérience parisienne authentique.",
          image: "/arrondissementsdetails/neuilly.jpg",

        }

        // Ajoutez d'autres arrondissements ici
      ],
    };
  },
  computed: {
    selectedArrondissement() {
      const id = parseInt(this.$route.params.id);
      return this.arrondissements.find(
          (arrondissement) => arrondissement.id === id
      );
    }
    ,
  }
  ,
};
</script>

<style scoped>
.img-fluid {
  border-radius: 25px;
}
</style>
