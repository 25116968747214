// router.js

import { createRouter, createWebHashHistory } from 'vue-router';

// Importez vos composants de page ici
import Home from '../pages/Home';
import About from '../pages/Questions';
import QSN from '../pages/QSN';
import Services from "@/pages/Services";
import LogementDetails from "@/components/Logement/LogementDetails";
import Api from "@/components/Logement/Api";
import LogementInfos from "@/components/Logement/LogementInfos";
import Logements from "@/pages/Logements";
import MentionsLegales from "@/pages/MentionsLegales";
import Conditions from "@/pages/Conditions";
import Formulaire from "@/pages/Formulaire";
import Arrondissement from "@/pages/Arrondissement";
import ListingByArrondissement from "@/components/Logements/ListingByArrondissement";
import Proprietaire from "@/pages/Proprietaire";
import ListingByRoom from "@/components/Logements/ListingByRoom";
import NotFound from "@/components/NotFound";

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
    },
    {
        path: '/:catchAll(.*)*',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/questions',
        component: About,
    },
    {
        path: '/qui-sommes-nous',
        component: QSN,
    },
    {
        path: '/services',
        component: Services,
    },
    {
        path: '/logements',
        component: Logements,
    },
    {
        path: '/logement/:id',
        name: 'logement-details',
        components: {
            default: LogementDetails, // Composant par défaut pour /logement/:id
            api: Api, // Composant pour /logement/:id/api
            infos: LogementInfos, // Composant pour /logement/:id/infos
        },
        props: {
            default: true,
            api: true,
            infos: true,
        },
    },
    {
        path: '/mentionslegales',
        component: MentionsLegales,
    },
    {
        path: '/conditions',
        component: Conditions,
    },
    {
        path: '/contact',
        component: Formulaire,
    },
    {
        path: '/listingbyarrondissement',
        component: ListingByArrondissement,
    },
    {
        path: '/listingbyroom',
        component: ListingByRoom,
    },
    {
        name: 'Proprietaire',
        path: '/proprietaire',
        component: Proprietaire,
    },
    {
        path: '/arrondissement/:id',
        name: 'arrondissement', // Assurez-vous que le nom de la route est correct
        component: Arrondissement,
        props: true,
    },

];


const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition; // Utilisez la position enregistrée si disponible
        } else {
            return { behavior: 'smooth', top: 0 }; // Faites défiler vers le haut de la page avec un effet smooth
        }
    },
});

export default router;
