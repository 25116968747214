<template>
  <NavBar/>
  <Search/>
  <Misenavant/>
  <Footer/>
</template>

<script>

import Search from "@/components/Search";
import Footer from "@/components/Footer";
import NavBar from "@/components/Navbar";
import Misenavant from "@/components/Misenavant";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'NotFound',
  components: {
    Misenavant,
    NavBar,
    Footer,
    Search
  }
}
</script>

<style>

body{
  background-color: #f8f9fa!important;
}

p{
  font-size: 1rem;
}


</style>
