<template>
  <NavBar/>
  <div class="container conditions-utilisation">
    <h1 class="mt-4">Conditions générales d'utilisation</h1>

    <h2 class="mt-4">Identification de la société</h2>
    <p>
      La société Altius (« Altius ») est une société par actions simplifiée, inscrite au Registre du commerce et des
      sociétés de Paris sous le numéro 977 821 628, dont le siège social est situé 23 rue de Lombards -
      75004 Paris.
    </p>

    <p>
      Residor peut être contactée aux coordonnées suivantes :
    </p>

    <ul>
      <li>téléphone : 01.44.78.75.63</li>
      <li>adresse électronique : hello@residor-paris.com</li>
    </ul>

    <h2 class="mt-4">Services proposés</h2>
    <p>
      Residor propose des services « clés en main » de gestion de la location (les « Services ») d’un bien immobilier
      (le « Logement ») à destination des propriétaires ou de toute autre personne autorisée par celui-ci à proposer le
      Logement à la location (le « Bailleur »).
    </p>

    <p>
      Le Logement, quel que soit sa nature, peut faire l’objet à la location saisonnière au sens de l’article 1-1 2° de
      la loi du 2 janvier 1979 qui correspond à « la location d'un immeuble conclue pour une durée maximale et non
      renouvelable de 90 jours consécutifs » et doit répondre à la définition légale du « meublé de tourisme » au sens
      de l’article D. 324-1 du Code de Tourisme, défini comme « des villas, appartements, ou studios meublés, à l’usage
      exclusif du locataire, offerts en location à une clientèle de passage qui y effectue un séjour caractérisé par une
      location à la journée, à la semaine ou au mois, et qui n'y élit pas domicile ».
    </p>

    <p>
      Les Services sont accessibles à l’adresse www.residor-paris.com ou via une application mobile compatible avec iOS ou
      Android (ensemble et indifféremment la « Plateforme »).
    </p>

    <p>
      Residor et les Bailleurs sont ci-après désignés ensemble ou individuellement une « Partie ».
    </p>

    <h2 class="mt-4">Documents contractuels</h2>
    <p>
      La relation contractuelle entre Residor et le Bailleur est régie, par ordre hiérarchique décroissant, par les
      documents suivants :
    </p>

    <p>
      Le mandat (le « Mandat ») : Le Mandat décrit les conditions dans lesquelles le Bailleur confie à Residor la
      gestion du Logement pour sa location. Residor génère et transmet au Bailleur le Mandat qu’il doit signer et
      retourner à Residor dans un délai de 14 jours à compter de son émission.
    </p>

    <p>
      Les conditions générales (les « Conditions Générales ») : Les Conditions Générales fixent les modalités et
      conditions par lesquelles Residor fournit les Services au Bailleur ainsi que les droits et obligations respectives
      des Parties dans ce cadre.
    </p>

    <p>
      Le Bailleur accepte les Conditions Générales en cochant une case dans le formulaire d’inscription et dans le
      Mandat. S’il n’accepte pas l’intégralité des Conditions Générales, il ne peut pas accéder aux Services.
    </p>

    <p>
      Elles sont accessibles par un lien direct en bas de page de la Plateforme.
    </p>

    <p>
      Les Conditions Générales peuvent être complétées par des conditions particulières, qui, en cas de contradiction,
      prévalent sur les Conditions Générales.
    </p>

    <h2 class="mt-4">Conditions d’accès à la Plateforme et aux Services</h2>
    <p>
      Les Services sont fournis aux Bailleurs qui remplissent les conditions cumulatives suivantes :
    </p>

    <ul>
      <li>Le Bailleur doit être une personne physique disposant de la pleine capacité juridique, ou une personne morale
        agissant par l’intermédiaire d’une personne physique disposant du pouvoir ou de l’habilitation requise pour
        contracter au nom du Bailleur et pour son compte ;
      </li>
      <li>Le Bailleur a la qualité de :
        <ul>
          <li>consommateur, entendu comme toute personne physique qui agit à des fins qui n'entrent pas dans le cadre de
            son activité professionnelle ;
          </li>
          <li>professionnel, entendu comme toute personne physique ou morale s’agissant à des fins entrant dans le cadre
            de son activité commerciale, industrielle, artisanale, libérale ou agricole.
          </li>
        </ul>
      </li>
      <li>Le Bailleur dispose d’un Logement qui répond aux conditions d’éligibilité aux Services telles que décrites sur
        la Plateforme.
      </li>
    </ul>

    <h2 class="mt-4">Modalités de souscription aux Services</h2>
    <p>
      Pour souscrire aux Services, le Bailleur doit :
    </p>

    <ul>
      <li>remplir le formulaire d’inscription sur la Plateforme, en complétant toutes les informations requises,
        notamment son nom, prénom et adresse email ;
      </li>
      <li>choisir un mot de passe. Le Bailleur a la possibilité de changer son mot de passe à tout moment. Il est
        recommandé de changer régulièrement de mot de passe. Le Bailleur reconnaît et accepte que l’adresse email
        renseignée sur le formulaire d’inscription constitue son identifiant de connexion ;
      </li>
      <li>cocher sur le formulaire d’inscription la case indiquant l’acceptation des Conditions Générales ;</li>
      <li>renvoyer le Mandat signé dans le délai indiqué à l’article « Documents contractuels ».</li>
    </ul>

    <p>
      Le Bailleur doit aussi fournir l’ensemble des informations marquées comme obligatoires, ce qu’il reconnaît et
      accepte. L’inscription du Bailleur est automatiquement validée et entraîne l’ouverture d’un compte (le « Compte »)
      permettant au Bailleur d’utiliser et de gérer les Services.
    </p>

    <p>
      Le Bailleur accède aux Services en se connectant à son Compte avec son adresse email et le mot de passe choisi
      lors de l’inscription.
    </p>

    <h2 class="mt-4">Durée</h2>
    <p>
      Les Services sont souscrits pendant la durée mentionnée au Mandat. Ce Mandat est tacitement reconduit sauf
      dénonciation par l’une ou l’autre des Parties dans les conditions prévues audit Mandat.
    </p>

    <h2 class="mt-4">Description des Services</h2>
    <p>
      Avant toute souscription, le Bailleur reconnaît qu’il peut prendre connaissance sur la Plateforme des
      caractéristiques des Services et de leurs contraintes, notamment techniques.
    </p>

    <p>
      Le Bailleur a accès aux Services qui suivent, sous une forme et selon les moyens techniques que Residor juge les
      plus appropriés.
    </p>

    <h3 class="mt-4">Les Services</h3>

    <h4 class="mt-4">Rédaction de l’annonce</h4>
    <p>
      Le Bailleur s’engage à remplir de manière exhaustive le formulaire d’informations mis à sa disposition par Residor
      aux fins de rédiger une annonce (l’« Annonce »), en précisant notamment les informations suivantes :
    </p>

    <ul>
      <li>l’adresse du Logement ;</li>
      <li>ses caractéristiques principales (étage, etc) et plus généralement tout élément
        jugé pertinent par le Bailleur ;
      </li>
      <li>la période de disponibilité du Logement.</li>
    </ul>

    <p>
      Le Bailleur doit renseigner toutes les informations marquées comme obligatoires.
    </p>

    <p>
      Residor s’engage à rédiger une Annonce sur la base de ces informations et détermine seule le prix de la location
      du Logement à la nuit (le « Prix de location »), en fonction du prix du marché.
    </p>

    <p>
      Le Bailleur garantit à Residor la véracité des informations communiquées à Residor via le formulaire
      d’informations et sur la base desquelles l’Annonce est établie.
    </p>

    <h4 class="mt-4">Photographie du Logement</h4>
    <p>
      Le Bailleur reconnaît et accepte que Residor se réserve la possibilité de venir visiter le Logement préalablement
      à la publication de l’Annonce notamment pour y effectuer les photographies qui figureront dans l’Annonce. Le
      Bailleur s’engage à donner l’accès à son Logement sur simple demande de Residor.
    </p>

    <p>
      Residor intègre dans l’Annonce des photographies du Logement, qu’elle choisit librement et dont elle détermine
      seule le nombre.
    </p>

    <p>
      Le Bailleur peut adresser à Residor ses propres photographies du Logement et garantit que celle-ci ne violent pas
      les droits de tiers, notamment en termes de droit à l’image et de vie privée. Residor détermine seule si les
      photographies sont de qualité suffisante pour être insérées dans l’Annonce. Dans le cas contraire, le Bailleur
      accepte que Residor fasse réaliser des clichés complémentaires par le photographe de son choix. Residor se réserve
      le droit de facturer les frais liés aux prestations du photographe sous réserve d’en avertir au préalable le
      Bailleur, qui ne peut s’y opposer.
    </p>

    <p>
      Le Bailleur s’engage à se rendre disponible pour la prise de photographies de son Logement. Il reconnaît être
      informé qu’il sera contacté directement par un photographe mandaté par Residor, aux fins de déterminer les
      modalités de réalisation de la prestation et notamment fixer les date et heure de rendez-vous pour réaliser la
      séance photo du Logement.
    </p>

    <div class="container mt-5">
      <h4 class="mt-4">Publication de l’Annonce</h4>
      <p>Residor s’engage à publier l’Annonce sur des sites d’annonces de location de logements (les « Sites de
        location ») qui peuvent être :</p>
      <ul>
        <li>Le propre site d’annonces de location de logement de Residor accessible à l’adresse suivante : <a
            href="https://residor-paris.com/">https://residor-paris.com/</a></li>
        <li>Les sites tiers d’annonces de location de Logement dont la liste figure sur la Plateforme.</li>
      </ul>
      <p>Le Bailleur reconnaît et accepte que Residor détermine seule les Sites de location sur lesquels elle publie
        l’Annonce.</p>
      <p>La publication de l’Annonce est valable, sauf mention contraire, pendant toute la durée de l’utilisation des
        Services par le Bailleur, sous réserve de la disponibilité du Logement.</p>
      <p>Le Bailleur est informé et accepte que toute Annonce incomplète pour laquelle le Bailleur n’a pas rempli en
        intégralité le formulaire d’informations ne sera pas publiée.</p>

      <h4 class="mt-4">Modification de l’Annonce</h4>
      <p>L’Annonce est accessible depuis le Compte du Bailleur à titre informatif.</p>
      <p>Le Bailleur peut demander à Residor d’effectuer des modifications des informations relatives à l’Annonce.
        Residor procède, dans les meilleurs délais, à la modification de l’Annonce sur les différents Sites de
        location.</p>
      <p>Le Bailleur reconnait expressément que toute réservation du Logement, effectuée préalablement à la modification
        de l’Annonce sur les Sites de location, doit être honorée et que la responsabilité de Residor ne pourra être
        recherchée à ce titre. Conformément aux dispositions du Mandat, en cas d’annulation de la réservation du
        Logement par le Bailleur, ce dernier est redevable des frais inhérents d’annulation facturés par le Site de
        location.</p>

      <h4 class="mt-4">Gestion de la location du Logement</h4>
      <p>Residor propose au Bailleur diverses prestations relatives à la gestion de la location de son Logement, dont
        les modalités sont décrites dans le Mandat signé par le Bailleur lors de son inscription, et notamment :</p>
      <ul>
        <li>La gestion des réservations du Logement via les Sites de location. Residor s’engage à notifier au Bailleur,
          sur son Compte, les réservations confirmées de son Logement, ainsi que les informations y afférentes. Le
          Bailleur peut accéder à travers son Compte à l’historique des réservations en cours et passées ;
        </li>
        <li>La remise des clefs au locataire. Residor indiquera au Bailleur les modalités de remise des clefs, que le
          Bailleur s’engage à respecter, sauf impossibilité ;
        </li>
        <li>La réalisation de l’état des lieux général d’entrée et de sortie de chaque locataire. Ledit état des lieux
          se limite à la vérification du bon état général du Logement, et notamment à ce que Residor vérifie que le
          Logement n’a subi aucun dommage conséquent et visible. Le cas échéant, Residor informe le Bailleur des
          dégradations éventuelles constatées ;
        </li>
        <li>Residor assure l’entretien du Logement pour le compte du Bailleur pendant toute la durée de la location
          effective du Logement, à l’exclusion de toute réparation nécessitant l’exécution de travaux ;
        </li>
        <li>Residor effectue les prestations de ménage entre chaque période de location du Logement, ainsi que le
          nettoyage du linge de toilette et de maison. Le Bailleur reconnaît et accepte qu’Residor reste libre de
          sous-traiter lesdites prestations de ménage à tout prestataire de son choix ;
        </li>
        <li>Residor peut mobiliser, sur demande du bailleur ou de sa propre initiative les assurances ou garanties
          mises à disposition par les plateformes de location. Residor ne peut s’engager sur aucun résultat ou garantir
          le succès d’une demande d’indemnisation auprès de ces assurances.
        </li>
      </ul>
      <p>En tout état de cause, le Bailleur reconnaît et accepte que les services de gestion ci-avant sont fournis soit
        directement par Residor soit par tout prestataire de son choix.</p>

      <h4 class="mt-4">Perception du Prix de location</h4>
      <p>Residor est expressément mandatée aux termes des présentes par le Bailleur pour percevoir les Prix de location
        auprès des Sites de location, ou auprès des locataires directement.</p>
      <p>Les modalités de versement du Prix de location par Residor au Bailleur sont précisées dans le Mandat.</p>
      <p>Le Bailleur reconnait et accepte par ailleurs que la perception du Prix de location peut nécessiter qu’il
        indique des informations complémentaires exigées par les Sites de location. Residor s’engage à informer le
        Bailleur desdites informations dans les meilleurs délais afin que le Bailleur puisse percevoir le Prix de
        location.</p>

      <h4 class="mt-4">Autres Services</h4>
      <p>Residor se réserve la faculté de proposer tout autre Service, sous une forme et selon les fonctionnalités et
        moyens techniques qu’elle estimera les plus appropriés pour rendre lesdits Services.</p>

      <h4 class="mt-4">Les prestations complémentaires liées à la Plateforme</h4>
      <p>Maintenance</p>
      <p>Le Bailleur bénéficie pendant la durée des Services d’une maintenance, notamment corrective et évolutive. Dans
        ce cadre, l’accès à la Plateforme peut être limité ou suspendu.</p>
      <p>Concernant la maintenance corrective, Residor fait ses meilleurs efforts pour fournir au Bailleur une
        maintenance corrective afin de corriger tout dysfonctionnement ou bogue relevé sur la Plateforme.</p>
      <p>Concernant, la maintenance évolutive, le Bailleur bénéficie pendant la durée des Services d’une maintenance
        évolutive, que Residor pourra réaliser automatiquement et sans information préalable, et qui comprend des
        améliorations des fonctionnalités de la Plateforme et/ou installations techniques utilisées dans le cadre de la
        Plateforme (visant à introduire des extensions mineures ou majeures).</p>
      <p>L’accès à la Plateforme peut par ailleurs être limité ou suspendu pour des raisons de maintenance planifiée,
        qui peut inclure les opérations de maintenance corrective et évolutive susvisées.</p>

      <p>Hébergement</p>
      <p>Residor assure, dans les termes d’une obligation de moyens, l’hébergement de la Plateforme, ainsi que des
        données produites et/ou saisies par/sur la Plateforme, sur ses serveurs ou par l’intermédiaire d’un prestataire
        d’hébergement professionnel, et sur des serveurs situés dans un territoire de l’Union européenne.</p>

      <p>Assistance technique</p>
      <p>En cas de difficulté rencontrée lors de l’utilisation des Services, le Bailleur peut contacter Residor aux
        coordonnées mentionnées à l’article « Identification de la société ».</p>

      <h4 class="mt-4">Programme de parrainage</h4>
      <p>Le Bailleur a la possibilité de parrainer une ou plusieurs personnes de sa connaissance afin qu’elles
        souscrivent à leur tour à la Plateforme et aux Services en tant que Bailleur.</p>
      <p>En contrepartie de la souscription d’une personne parrainée à la Plateforme et aux Services, le Bailleur
        parrain et le Bailleur parrainé bénéficieront d’une réduction sur la commission due à Residor.</p>
      <p>Les conditions de mise en œuvre du Service de parrainage sont précisées dans le règlement de parrainage qui
        peut être consulté sur la Plateforme.</p>

      <h4 class="mt-4">Conditions financières</h4>
      <p>Commission de Residor</p>
      <p>En contrepartie des Services, Residor perçoit une commission (la « Commission ») qui correspond à un
        pourcentage fixe calculée sur le Prix de la location tel qu’indiqué sur le Compte et/ou dans le Mandat.</p>
      <p>Frais liés à l’exécution des Services</p>
      <p>Les frais engagés par Residor dans le cadre de l’exécution des Services pourront être supportés par le
        Bailleur sous réserve que celui-ci soit informé préalablement à l’engagement de ces frais.</p>
      <p>Le cas échéant, ces frais seront déduits par Residor du Prix de location effectivement perçus par Residor au
        titre de la location du Logement.</p>

      <h4 class="mt-4">Droit de rétractation du Bailleur</h4>
      <p>Les modalités relatives au droit de rétractation du Bailleur sont précisées dans le Mandat.</p>

      <h4 class="mt-4">Obligations du Bailleur</h4>
      <p>Concernant la fourniture d’informations</p>
      <p>Le Bailleur s’engage à fournir à Residor toutes les informations nécessaires pour la souscription et
        l’utilisation des Services, notamment toutes les informations nécessaires à la rédaction de l’Annonce.</p>
      <p>En cas de manquement, Residor se réserve la possibilité de prendre toutes mesures appropriées selon les
        conditions et modalités détaillées à l’article « Sanction des manquements » ci-après.</p>

      <p>Concernant le Compte du Bailleur</p>
      <p>Le Bailleur :</p>
      <ul>
        <li>garantit Residor que les informations renseignées sur le formulaire d’inscription sont exactes et qu’elles
          ne sont entachées d’aucun caractère trompeur, et s’engage à les mettre à jour ;
        </li>
        <li>reconnaît et accepte que les informations saisies aux fins de création ou de mise à jour de son Compte
          valent preuve de son identité ;
        </li>
        <li>est responsable du maintien de la confidentialité et de sécurité de son identifiant et mot de passe. Tout
          accès à la Plateforme à l’aide de ces derniers est réputé effectué par lui.
        </li>
      </ul>
      <p>Le Bailleur doit immédiatement contacter Residor aux coordonnées mentionnées à l’article « Identification de
        la société » s’il remarque que son Compte a été utilisé à son insu. Il reconnaît à Residor le droit de prendre
        toutes mesures appropriées en pareil cas.</p>

      <p>Concernant la disponibilité du Logement</p>
      <p>Le Bailleur garantit à Residor la disponibilité du Logement sur les périodes de disponibilités renseignées sur
        la fiche d’information, et notamment qu’il est intégralement inoccupé sur ces périodes et en état d’être loué
        (pas de travaux en cours, de pièces condamnées, de dégâts des eaux ou en tout genre, d’appareils qui ne seraient
        pas en état de marche, de fenêtres brisées, etc). Chaque période de mise en location devra être d’au moins 5
        nuits consécutives.</p>
      <p>Le Bailleur s’engage à ne pas annuler une période de location du Logement dès qu’une réservation a été
        enregistrée par Residor pour ladite période. S’il annule néanmoins la location pour ladite période, il
        reconnaît être informé qu’Residor lui facturera les frais d’annulation prévus par les Sites de location.</p>
      <p>Il reste libre de modifier la disponibilité du Logement pour les jours pour lesquels aucune réservation n’est
        enregistrée par Residor à la date de la modification de la disponibilité.</p>
      <p>Dans l’hypothèse où le Bailleur n’honorerait pas les réservations et les annulerait en violation des
        dispositions ci-dessus et/ou ne s’acquitterait pas de toute facture de remboursement dû, ce dernier devra
        indemniser Residor de tous frais et charges subis en lien avec cette annulation et notamment les surcoûts liés
        au relogement des locataires. Par ailleurs, Residor se réserve la possibilité de prendre toutes mesures
        appropriées selon les conditions et modalités détaillées à l’article « Sanction des manquements » ci-après.</p>

      <p>Concernant l’utilisation des Services</p>
      <p>Le Bailleur est responsable de son utilisation des Services et de toute information qu’il partage dans ce
        cadre. Il s’engage à utiliser les Services personnellement et à ne permettre à aucun tiers de les utiliser à sa
        place ou pour son compte.</p>
      <p>Le Client s’interdit de détourner les Services à des fins autres que celles pour lesquelles ils ont été conçus,
        et notamment pour :</p>
      <ul>
        <li>exercer une activité illégale ou frauduleuse,</li>
        <li>porter atteinte à l’ordre public et aux bonnes mœurs,</li>
        <li>porter atteinte à des tiers ou à leurs droits, de quelque manière que ce soit,</li>
        <li>violer une disposition contractuelle, législative ou règlementaire,</li>
        <li>exercer toute activité de nature à interférer dans le système informatique d’un tiers notamment aux fins
          d’en violer l’intégrité ou la sécurité,
        </li>
        <li>aider ou inciter un tiers à commettre un ou plusieurs actes ou activités listés ci-dessus.</li>
      </ul>
      <p>Le Client s’interdit également de :</p>
      <ul>
        <li>copier, modifier ou détourner tout élément appartenant à Residor ou tout concept qu’elle exploite dans le
          cadre des Services,
        </li>
        <li>adopter tout comportement de nature à interférer avec ou détourner les systèmes informatiques de Residor ou
          porter atteinte à ses mesures de sécurité informatique,
        </li>
        <li>porter atteinte aux droits et intérêts financiers, commerciaux ou moraux de Residor,</li>
        <li>commercialiser, transférer ou donner accès de quelque manière que ce soit aux Services, aux informations
          hébergées sur la Plateforme ou à tout élément appartenant à Residor.
        </li>
      </ul>
      <p>Afin de permettre à Residor d’assurer la mise en œuvre des Services auprès des locataires, le Bailleur
        s’engage à :</p>
      <ul>
        <li>fournir à Residor l’ensemble des informations et du matériel nécessaire pour accéder au Logement (clefs,
          badges, etc.) et, plus généralement, tout document, élément, donnée et toute information qui lui est demandé
          par Residor et nécessaire à la bonne exécution des Services ;
        </li>
        <li>coopérer activement avec Residor en vue de la bonne exécution des présentes et à l’informer de toutes
          difficultés liées à cette exécution ;
        </li>
        <li>ne pas entraver, d’une quelque manière que ce soit les prestations rendues par Residor aux locataires ;
        </li>
        <li>répondre, dans un délai raisonnable, à toute demande formulée par Residor ;</li>
        <li>rendre le Logement accessible à Residor, aux locataires, ainsi qu’à ses prestataires.</li>
      </ul>

      <h4 class="mt-4">Concernant la mise en location du Logement du Bailleur</h4>
      <p>Le Bailleur garantit Residor qu’il dispose de toutes les autorisations nécessaires et de toute capacité pour
        mettre en location ou sous-location son Logement, et garantit que cela ne constitue pas une violation d’un
        contrat conclu avec un tiers, pendant toute la durée des Services.</p>
      <p>Dans ce cadre, le Bailleur garantit notamment Residor que :</p>
      <ul>
        <li>la location ou la sous-location du Logement via la Plateforme est conforme au règlement de copropriété de
          l’immeuble dans lequel il se trouve ;
        </li>
        <li>aucune disposition légale ou réglementaire, ni aucun arrêté municipal ne limite sa capacité à louer son
          Logement.
        </li>
        <li>il a procédé aux éventuelles déclarations imposées par les lois et règlements et a recueilli toutes les
          autorisations nécessaires pour s’engager dans le cadre des présentes.
        </li>
      </ul>
      <p>En particulier, le Bailleur reconnait expressément avoir pris connaissance et déclare sur l’honneur respecter
        les obligations qui lui incombent en tant que propriétaire ou personne autorisée à proposer à la location le
        Logement. Lesdites obligations sont reproduites dans le Mandat.</p>
    </div>
    <h4 class="mt-4">Concernant l’état du Logement du Bailleur</h4>
    <p>Le Bailleur est informé et accepte que les locations peuvent accélérer l’état d’usure du Logement. La
      responsabilité de Residor ne pourra en aucun cas être engagée pour tout dommage constaté sur le Logement, lié ou
      non à l’usure du Logement, éventuellement accentuée par une utilisation du Logement dans le cadre des Services qui
      permettent des locations répétées du Logement.</p>
    <p>Le Bailleur s’engage à ne pas conserver dans le Logement, pendant les périodes de disponibilité de celui-ci, de
      biens de valeur, tels que des bijoux ou des œuvres d’art ou à les conserver dans un coffre-fort sécurisé. Le
      Bailleur reconnaît que la responsabilité de Residor ne pourra en aucun cas être engagée en cas de vol ou de casse
      par un locataire.</p>
    <p>En cas de dommages constatés dans le Logement à la suite d’une réservation par un locataire :</p>
    <ul>
      <li>Si le dommage est constaté par Residor, celui-ci s’engage à notifier, dans les meilleurs délais, le Bailleur
        de l’existence dudit dommage, vol ou dégradation constaté au sein du Logement.
      </li>
      <li>Si le dommage est constaté par le Bailleur, celui-ci s’engage à notifier dans les conditions de l’article «
        Plainte », dans un délai de 7 jours à compter de la fin de la dernière réservation du Logement par un locataire
        pour notifier à Residor l’existence dudit dommage, vol, ou dégradation constaté au sien du Logement.
      </li>
    </ul>
    <p>A la suite de cette notification, Residor peut décider de mettre en œuvre, à sa libre discrétion, les mesures
      suivantes :</p>
    <ul>
      <li>fournir une aide au Bailleur, dans les conditions de l’article « Plainte », dans l’accomplissement de ses
        démarches auprès des Sites de location, de l’assurance et/ou des autorités. Residor s’engage notamment à lui
        communiquer tout élément en sa possession relatif au fait générateur du dommage ;
      </li>
      <li>décider du sort du dépôt de garantie éventuellement versé par le locataire ;</li>
    </ul>
    <p>En tout état de cause, le Bailleur reconnaît et accepte également que la responsabilité de Residor ne saurait en
      aucun cas être engagée en cas de dommages causés au sein du Logement par un locataire.</p>
    <h4 class="mt-4">Concernant la diffusion de contenu sur la Plateforme</h4>
    <p>Le Bailleur garantit à Residor qu’il dispose de tous les droits et autorisations nécessaire à la diffusion des
      contenus de toute nature (rédactionnels, graphiques, audios, audiovisuels ou autres) sur la Plateforme dans le
      cadre de l’utilisation des Services (les « Contenus »). Il s’engage à ce que ces Contenus soient licites, ne
      portent pas atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers, n’enfreignent aucune disposition
      législative ou règlementaire et plus généralement, ne soient aucunement susceptibles de mettre en jeu la
      responsabilité civile ou pénale de Residor.</p>
    <p>Le Bailleur s’interdit ainsi de diffuser, notamment et sans que cette liste soit exhaustive :</p>
    <ul>
      <li>des contenus pornographiques, obscènes, indécents, choquants ou inadaptés à un public familial, diffamatoires,
        injurieux, violents, racistes, xénophobes ou révisionnistes ;
      </li>
      <li>des contenus contrefaisants ;</li>
      <li>des contenus attentatoires à l’image d’un tiers ;</li>
      <li>des contenus mensongers, trompeurs ou proposant ou promouvant des activités illicites, frauduleuses ou
        trompeuses ;
      </li>
      <li>des contenus nuisibles aux systèmes informatiques de tiers (tels que virus, vers, chevaux de Troie, etc) ;
      </li>
      <li>et plus généralement tout contenu susceptible de porter atteinte aux droits de tiers ou d’être préjudiciable à
        des tiers, de quelque manière et sous quelque forme que ce soit.
      </li>
    </ul>
    <p>Dans ce cadre, le Bailleur garantit Residor contre toute plainte, revendication et/ou action d’un ou plusieurs
      autres Bailleurs et/ou de tout tiers soutenant que les Contenus constitueraient une violation de ses droits, quels
      qu’ils soient et notamment ses droits de propriété intellectuelle, droit à l’image et à la protection de la vie
      privée. En conséquence, le Bailleur s’engage à indemniser Residor de tout préjudice qu’elle subirait et à prendre
      à sa charge tous les dommages-intérêts, ainsi que les frais, charges et dépens auxquels Residor pourrait être
      condamnée, ou qui seraient prévus par un accord transactionnel signé par Residor en lien avec la violation par le
      Bailleur de ses obligations et garanties ci-dessus.</p>
    <h4 class="mt-4">Sanction des manquements</h4>
    <p>Sans préjudice des dispositions de l’article « Dénonciation / résiliation », en cas de manquement à l’une
      quelconque des dispositions des Conditions Générales, ou plus généralement, d’infraction aux lois et règlements
      par le Bailleur, Residor se réserve le droit de :</p>
    <ol>
      <li>suspendre, sans délai, l’accès aux Services du Bailleur auteur du manquement ou de l’infraction, ou y ayant
        participé, et notamment, en cas de fourniture d’informations erronées, incomplètes, mensongères ou périmées lors
        de l’inscription ;
      </li>
      <li>supprimer tout Contenu en lien avec le manquement ou l’infraction considéré(e), en totalité ou en partie ;
      </li>
      <li>prendre toutes mesures appropriées et engager toute action en justice ;</li>
      <li>avertir le cas échéant les autorités compétentes, coopérer avec elles et leur fournir toutes les informations
        utiles à la recherche et à la répression d’activités illégales ou illicites.
      </li>
    </ol>
    <p>Ces sanctions sont sans préjudice de tous dommages et intérêts que Residor pourrait réclamer au Bailleur.</p>

    <h4 class="mt-4">Obligations de Residor</h4>
    <p>Residor s’engage à faire ses meilleurs efforts pour fournir les Services avec diligence et selon les règles de
      l’art, étant précisé qu’il pèse sur elle une obligation de moyens, à l’exclusion de toute obligation de résultat,
      ce que le Bailleur reconnaît et accepte expressément.</p>

    <h4 class="mt-4">Concernant la qualité des Services</h4>
    <p>Residor fait ses meilleurs efforts pour fournir au Bailleur des Services de qualité. A cette fin, elle procède
      régulièrement à des contrôles afin de vérifier le fonctionnement et l’accessibilité de ses Services et de la
      Plateforme et peut ainsi réaliser une maintenance dans les conditions précisées à l’article « Maintenance ».</p>
    <p>Residor n’est néanmoins pas responsable des difficultés ou impossibilités momentanées d’accès à ses Services qui
      auraient pour origine :</p>
    <ul>
      <li>des circonstances extérieures à son réseau (et notamment la défaillance partielle ou totale des serveurs de
        Residor),
      </li>
      <li>la défaillance d’un équipement, d’un câblage, de services ou de réseaux non inclus dans ses Services ou qui
        n’est pas sous sa responsabilité,
      </li>
      <li>l’interruption des Services du fait des opérateurs télécoms ou fournisseurs d’accès à internet,</li>
      <li>l’intervention du Client notamment via une mauvaise configuration appliquée sur les Services,</li>
      <li>un cas de force majeure.</li>
    </ul>
    <p>Residor est responsable du fonctionnement de ses serveurs, dont les limites extérieures sont constituées par les
      points de raccordement.</p>
    <p>Par ailleurs, Residor ne garantit pas que :</p>
    <ul>
      <li>les Services, soumis à une recherche constante pour en améliorer notamment la performance et le progrès,
        seront totalement exempts d’erreurs, de vices ou défauts ;
      </li>
      <li>les Services étant standards et nullement proposés à la seule intention du Bailleur en fonction de ses propres
        contraintes personnelles, répondront spécifiquement aux besoins et attentes du Bailleur.
      </li>
    </ul>
    <p>Residor décline également toute responsabilité en cas de perte éventuelle d’informations, accessibles sur le
      Compte du Bailleur, le Bailleur devant s’assurer de sauvegarder une copie des informations qu’il juge nécessaires
      et ne pouvant prétendre à aucun dédommagement à ce titre.</p>
    <h4 class="mt-4">Concernant la garantie de niveau de service sur la Plateforme</h4>
    <p>Residor ne propose aucune garantie de niveau de service de la Plateforme.</p>
    <p>Toutefois, Residor fait ses meilleurs efforts pour maintenir un accès à la Plateforme 24h/24h et 7j/7j sauf en
      cas de maintenance planifiée dans les conditions définies à l’article « Maintenance » ou de force majeure.</p>

    <h4 class="mt-4">Concernant la mise en relation entre le Bailleur et les locataires</h4>
    <p>Residor ne saurait être responsable de toute annulation éventuelle d’une réservation par un locataire.</p>
    <p>Residor ne saurait en aucun cas être partie à quelques litiges éventuels que ce soit entre les Bailleurs et les
      locataires, concernant l’état du Logement ou l’intégrité de ses équipements, ou toutes autres garanties,
      déclarations ou obligations quelconques auxquelles le Bailleur serait tenu dans ce cadre.</p>
    <p>Par ailleurs, Residor ne garantit aucun volume d’affaires que le Bailleur pourrait réaliser à travers
      l’utilisation des Services.</p>

    <h4 class="mt-4">Concernant la publicité sur la Plateforme</h4>
    <p>Residor peut publier et/ou envoyer au Bailleur tous messages publicitaires ou promotionnels en le renvoyant
      notamment vers des plateformes tierces.</p>
    <p>Residor n’est néanmoins pas responsable de :</p>
    <ul>
      <li>la disponibilité technique et des contenus, produits et/ou services de ces plateformes,</li>
      <li>les relations du Client nouées par l’intermédiaire de ces plateformes.</li>
    </ul>

    <h4 class="mt-4">Concernant l’inscription sur la liste d’opposition au démarchage téléphonique</h4>
    <p>Conformément à la loi n°2014-344 du 17 mars 2014, Residor informe le Bailleur ayant la qualité de consommateur
      au sens du code de la consommation qu’il a la possibilité de s’inscrire gratuitement sur la liste d’opposition au
      démarchage téléphonique BLOCTEL (<a href="www.bloctel.gouv.fr">www.bloctel.gouv.fr</a>) afin de ne plus être
      démarché téléphoniquement par un professionnel avec lequel il n’a pas de relation contractuelle en cours.</p>

    <h4 class="mt-4">Concernant le recours à la sous-traitance et la cession</h4>
    <p>Residor peut recourir à des sous-traitants dans le cadre de l’exécution des Services, qui sont soumis aux mêmes
      obligations que les siennes dans le cadre de leur intervention. Elle reste néanmoins seule responsable de la bonne
      exécution des Services à l’égard du Bailleur.</p>
    <p>Residor peut se substituer toute personne qui sera subrogée dans tous ses droits et obligations au titre de sa
      relation contractuelle avec le Bailleur. Le cas échéant, elle informera le Bailleur de cette substitution par tout
      moyen écrit.</p>

    <h4 class="mt-4">Responsabilité de Residor</h4>
    <p>Sans préjudice des autres dispositions prévues dans les Conditions Générales, la responsabilité de Residor est
      exclusivement limitée à la fourniture des Services selon les modalités décrites aux présentes, à l’exclusion de
      toute autre prestation et reste limitée aux seuls dommages directs avérés que le Bailleur subit du fait de
      l’utilisation des Services.</p>

    <h4 class="mt-4">Plainte</h4>
    <p>Sans préjudice des dispositions prévues à l’article 11.6, en cas d’acte portant préjudice à un Bailleur commis
      sur la Plateforme et/ou dans le cadre des Services, le Bailleur peut transmettre une plainte à Residor ou au
      locataire par email à l'adresse <a href="mailto:hello@residor-paris.com">hello@residor-paris.com</a>, dans un délai de 7
      jours suivant la fin de la dernière réservation du Logement par un locataire.</p>
    <p>La plainte doit impérativement comporter la date de sa notification, l’identité du plaignant (nom, prénom,
      adresse du logement concerné), l’identifiant de la personne à l’origine du préjudice, la description des faits
      litigieux accompagnée le cas échéant d’un lien vers la page web permettant d’en rapporter la preuve ou une facture
      s’il s’agit d’une dégradation ou d’un vol.</p>
    <p>Residor aura la libre faculté de prendre toutes mesures appropriées, sans aucun engagement de sa part et/ou de
      transmettre cette plainte aux autorités compétentes.</p>
    <p>En tout état de cause, l’intervention de Residor au titre du présent article se limite à un rôle de médiateur
      afin d’aider le Bailler à résoudre son litige. Les Bailleurs feront leur affaire personnelle des actions qu’ils
      pourraient introduire devant toute juridiction contre un autre Bailleur ou un locataire en raison du préjudice
      subi de son fait.</p>

    <h4 class="mt-4">Dénonciation / Résiliation</h4>
    <p>Le Bailleur peut cesser d’utiliser les Services en ne proposant plus de dates de disponibilité du Logement depuis
      son Compte. Si des réservations sont déjà enregistrées et que le Bailleur souhaite les annuler il devra
      s’acquitter de frais décris au paragraphe 11.3.</p>
    <p>Il peut se désinscrire des Services dans les conditions prévues dans le Mandat à l’article « Durée du Mandat
      ».</p>
    <p>La désinscription entraîne la fin des Services.</p>

    <h4 class="mt-4">Propriété intellectuelle</h4>
    <p>Les Conditions Générales ne confèrent au Bailleur aucun droit de propriété intellectuelle d’aucune sorte sur la
      Plateforme, ou sur aucun des textes, images, contenus audio-visuels et autres contenus, exploités par Residor sur
      la Plateforme (à l’exclusion des Contenus mis en ligne par le Bailleur en question), en ce compris les Conditions
      Générales, les marques, noms commerciaux et logos, les logiciels, structures, infrastructures et bases de données
      utilisés par Residor au sein de la Plateforme, qui demeurent la propriété exclusive, pleine et entière de
      Residor.</p>
    <p>Tout acte de désassemblage, décompilation, décryptage, extraction, réutilisation, et plus généralement toute
      reproduction, représentation, distribution, adaptation, commercialisation de la Plateforme et/ou des textes,
      images, contenus exploités par Residor sur la Plateforme par le Bailleur, non conforme aux dispositions des
      Conditions Générales, sont interdits et pourront faire l’objet de poursuites judiciaires.</p>
    <p>Le Bailleur autorise expressément Residor, pour toute la durée d’utilisation des Services, et pour le monde
      entier, à titre non exclusif, personnel et non transférable, à reproduire, représenter, adapter, modifier,
      transformer les Contenus publiés par le Bailleur sur la Plateforme, aux seules fins de l’exécution des Services
      par Residor et de promotion de ceux-ci. Residor s’interdit expressément toute autre utilisation des
      Contenus.</p>

    <div class="container mt-4">
      <h4>Données à caractère personnel</h4>
      <p>Traitement de données à caractère personnel en qualité de responsable de traitement</p>
      <p>Residor traite les données à caractère personnel du Bailleur en qualité de responsable de traitement au sens
        de la réglementation applicable au traitement de données à caractère personnel et, en particulier, le règlement
        (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (ci-après le « RGPD ») et la loi n°78-17 du 6
        janvier 1978 relative à l’informatique, aux fichiers et aux libertés (ci-après dénommés ensemble la «
        Réglementation applicable »), aux fins de donner l’accès à la Plateforme au Bailleur et dans le cadre de la
        gestion de la relation commerciale avec le Bailleur. A cette fin, Residor pratique une politique de protection
        des données personnelles dont les caractéristiques sont explicitées dans la Politique de confidentialité,
        accessible sur la page d’accueil de la Plateforme et dont le Bailleur est expressément invité à prendre
        connaissance.</p>
      <p>Traitement de données à caractère personnel en qualité de responsables conjoints des traitements</p>
      <p>Dans le cadre spécifique de la gestion des réservations via les Sites de location tiers, Residor et le
        Bailleur sont amenés à collecter et traiter les données à caractère personnel des locataires. Compte tenu de la
        nature de leurs relations et de l’objet de leur coopération, Residor et le Bailleur interviennent tous les deux
        dans la détermination des finalités et/ou des moyens des traitements en cause. Residor n’agit pas uniquement
        sur instructions, au nom et pour le compte du Bailleur mais définit des éléments structurants des Services.</p>
      <p>En conséquence, les Parties agissent en qualité de responsables conjoints de traitement au sens de la
        Réglementation applicable. Chaque Partie s’engage à traiter dans le cadre et pendant toute la durée des
        présentes, les données à caractère personnel auxquelles elle a accès ou qu’elle traite conformément aux
        exigences de la Réglementation applicable.</p>
      <p>Les Parties détaillent en Annexe 1 les mesures techniques et organisationnelles mises en œuvre par chacune
        d’elle, et plus généralement leurs engagements respectifs afin d’assurer la protection des données à caractère
        personnel et la conformité des traitements à la Réglementation applicable.</p>
    </div>
    <div class="container mt-4">
      <h4>Assurance</h4>
      <p>Chacune des Parties garantit, le cas échéant, l’autre Partie avoir souscrit les polices d’assurance
        nécessaires
        pour assurer et garantir les conséquences de sa responsabilité civile au cas où elle serait engagée, de
        manière
        à couvrir les conséquences pécuniaires des dommages dont elle aurait à répondre.</p>
      <p>En outre, le Bailleur garantit expressément à Residor avoir souscrit une assurance « multirisque habitation
        »
        sur chacun des Logements, incluant la garantie responsabilité civile familiale et couvrant la responsabilité
        civile des locataires et sous-locataires, ainsi que le vol, le bris, l’incendie et toutes autres dégradations
        susceptibles d’être commises par un locataire.</p>
      <p>Chacune des Parties s’engage à maintenir ces polices d’assurance pendant toute la durée d’exécution des
        présentes.</p>
      <p>Chacune des Parties s’engage à produire à la première demande de l’autre Partie les attestations d’assurance
        correspondantes.</p>
      <p>Toute modification, suspension ou résiliation des polices d’assurance par l’une ou l’autre des Parties devra
        être portée à la connaissance de l’autre Partie, sans délai, par tout moyen utile et notamment par email.</p>
    </div>
    <div class="container mt-4">
      <h4>Force majeure</h4>
      <p>Residor ne pourra être tenue responsable vis-à-vis du Bailleur dans l’hypothèse où l’exécution de ses
        obligations serait retardée, restreinte ou rendue impossible du fait de la survenance d’un évènement de force
        majeure, telle qu’elle est définie à l’article 1218 du Code civil. Les Parties conviennent d’entendre par
        force
        majeure les événements habituellement reconnus comme tels par la loi et les tribunaux français.</p>
      <p>La survenance d’un évènement de force majeure suspendra dans un premier temps l’exécution des Services
        pendant
        une durée qui ne pourra être supérieure à un mois. Dans l’hypothèse où le cas de force majeure se poursuivrait
        au-delà de la période susvisée, les Parties pourront résilier les présentes Conditions générales, de plein
        droit, sans formalité judiciaire, sans préavis et sans droit à indemnités de quelque nature que ce soit, par
        l’envoi d’une lettre recommandée avec accusé de réception ayant effet immédiat.</p>
    </div>
    <div class="container mt-4">
      <h4>Nullité</h4>
      <p>Si l’une quelconque des dispositions des présentes Conditions Générales venait à être déclarée nulle ou non
        applicable en raison d’une loi, d’un règlement ou à la suite d’une décision définitive rendue par une
        juridiction compétente, seule cette disposition serait frappée de nullité, les autres dispositions garderont
        toute leur force et leur portée.</p>
    </div>
    <div class="container mt-4">
      <h4>Modification des Conditions Générales</h4>
      <p>Residor se réserve la faculté de modifier à tout moment les Conditions Générales.</p>
      <p>Le Bailleur sera informé de ces modifications par tout moyen utile, au moins 30 jours avant leur entrée en
        vigueur. Les Conditions Générales modifiées s’appliqueront lors du renouvellement du Mandat.</p>
      <p>Le Bailleur qui n’accepte pas les Conditions Générales modifiées doit se désinscrire des Services dans les
        conditions de l’article « Dénonciation / Résiliation ».</p>
      <p>Tout Bailleur qui a recours aux Services postérieurement à l’entrée en vigueur des Conditions Générales
        modifiées est réputé avoir accepté ces modifications qui lui seront pleinement applicables.</p>
    </div>
<!--    <div class="container mt-4">
      <h4>Médiation</h4>
      <p>En cas de litige opposant le Bailleur ayant la qualité de consommateur au sens du code de la consommation
        et
        Residor, le Bailleur peut recourir gratuitement au médiateur de la consommation suivant en vue d’une
        résolution
        amiable :</p>
      <p>Centre de médiation de la consommation de conciliateurs de justice (CM2C)</p>
      <p>Adresse postale : 14 rue Saint Jean 75017 Paris</p>
      <p>Téléphone : 01 89 47 00 14</p>
      <a href="https://www.cm2c.net">https://www.cm2c.net</a>
      <h4>Mode de preuve admis</h4>
      <p>Les Parties sont libres de rapporter toutes preuves utiles au soutien de leurs prétentions conformément aux
        règles de droit commun. Le Bailleur reconnait et accepte expressément que toutes les données recueillies sur
        la
        Plateforme et les Sites de location font foi de la réalité des opérations intervenues dans le cadre des
        Services.</p>
    </div>-->
    <div class="container mt-4">
      <h4>Loi applicable et juridictions compétentes</h4>
      <p>Les Conditions Générales sont régies par la loi française.</p>
      <p>En cas de litige opposant le Bailleur ayant la qualité de professionnel au sens du code de la
        consommation et
        Residor, et à défaut d’accord amiable dans les 2 mois suivant la première notification, celui-ci sera
        soumis
        à
        la compétence exclusive des tribunaux de Paris (France), sauf dispositions impératives contraires.</p>
    </div>
    <div class="container mt-4">
      <h4>Annexe 1 – Protection des Données Personnelles et Coresponsabilité des traitements</h4>
      <p>Au sens de la présente Annexe, Residor et le Bailleur sont responsables conjoints des traitements
        listés
        ci-dessous.</p>
      <p>Finalités des traitements : Gestion des réservations via les Sites de location tiers</p>
      <p>Nature des opérations de traitement : Collecte, enregistrement, organisation, conservation,
        modification,
        consultation, utilisation, diffusion, effacement.</p>
      <p>Catégories de données à caractère personnel concernées :</p>
      <ul>
        <li>Données d’identification du locataire (nom, prénom, email, numéro de téléphone)</li>
        <li>Avis relatif à la location du Logement</li>
        <li>Photographies du Logement</li>
        <li>Données relatives à la carte bancaire du locataire</li>
      </ul>
      <p>Catégories de personnes concernées: Locataires</p>
      <p>Durée des traitements: Durée du présent Contrat</p>
      <p>Chaque Partie n’intervient sur les données à caractère personnel listées ci-dessus qu’en application
        des
        finalités listées ci-dessus. La responsabilité conjointe des traitements est strictement limitée à ce
        périmètre.</p>
      <p>En conséquence, tout autre traitement des données à caractère personnel qui serait effectué par l’une
        ou
        l’autre des Parties, en dehors du périmètre convenu serait effectué de son seul fait et sous sa seule
        responsabilité. Il appartiendra le cas échéant à la seule Partie en cause, responsable unique des
        traitements
        en
        cause, d’assurer la conformité des traitements à la Réglementation applicable. En aucun cas l’autre
        Partie ne
        saurait voir sa responsabilité engagée ou mise en cause de quelque façon que ce soit en raison d’un
        traitement
        effectué par l’autre Partie en dehors du périmètre visée à la présente Annexe. Il est rappelé que dans
        le
        cadre
        de leurs relations et du Contrat, chacune des Parties est responsable unique des conséquences de son
        éventuel
        manquement aux engagements qu’elle contracte en matière de protection des données à caractère personnel
        au
        sein
        de la présente Annexe, l’autre Partie étant susceptible de la poursuivre en indemnisation des indemnités
        et/ou
        amendes administratives qu’elle serait amené à verser en vertu de l’article précédent, conformément à
        l’article
        82, 5° du RGPD.</p>
      <p>Les Parties se répartissent leurs obligations respectives comme suit :</p>
      <ul>
        <li>Chacune des Parties met en œuvre les mesures techniques et organisationnelles appropriées afin de
          garantir
          la confidentialité, l’intégrité, la disponibilité et la résilience des données à caractère personnel
          qu’elle
          traite, ainsi que les moyens permettant de rétablir la disponibilité ou l’intégrité et la sécurité des
          données
          à caractère personnel et l’accès à celles-ci dans les meilleurs délais en cas d’incident. Les Parties
          s’engagent à mettre en œuvre des mesures afin de tester, analyser et évaluer régulièrement
          l’efficacité de
          ces
          mesures.
        </li>
        <li>Residor est le point de contact des personnes concernées pour l’exercice de leurs droits et
          s’engage à
          informer les personnes concernées des traitements réalisés dans le cadre de cette Annexe, dans la
          mesure où
          Residor est en contact direct avec les personnes concernées. Residor s’engage à traiter toute
          demande des
          personnes concernées reçues dans les délais légaux applicables. Le Bailleur apportera à Residor toute
          l’assistance utile afin de permettre l’exercice de leurs droits par les personnes concernées et lui
          transmettra toute demande de ce type dont il aura connaissance.
        </li>
        <li>Les Parties s’engagent à coopérer entre elle et à se fournir mutuellement toute information ou
          document
          utile afin de se conformer à la Réglementation applicable notamment dans le cadre du renseignement par
          chacune
          de son propre registre des activités de traitement, toute analyse d’impact relative à la protection
          des
          données et toutes demandes ou consultations auprès de l’autorité de contrôle.
        </li>
        <li>Chaque Partie est autorisée à engager un ou plusieurs sous-traitants dans le cadre des traitements
          faisant
          l’objet de la présente Annexe (ci-après le « Sous-traitant ultérieur ») sous réserve d’en informer
          préalablement et par écrit l’autre Partie. Cette information doit indiquer clairement les activités de
          traitement sous-traitées, l’identité et les coordonnées du Sous-traitant ultérieur et les dates du
          contrat
          de
          sous-traitance. La Partie notifiée dispose d’un délai de 15 (quinze) jours calendaires à compter de la
          date
          de
          réception de cette information pour présenter ses objections légitimes et motivées. A défaut de
          notification
          d’objections passé ce délai, la Partie notifiée sera réputée avoir accepté le recours au Sous-traitant
          ultérieur concerné. A la date de signature de la présente Annexe, chacune des deux Parties accepte le
          recours
          par l’autre aux Sous-traitants ultérieurs listés ci-dessous :
        </li>
      </ul>
      <p>Nom du Sous-traitant Ultérieur : Webhelp</p>
      <p>Activités de traitement sous-traitées : Accès et modification des données</p>
      <p>Localisation du Sous-traitant ultérieur : Maroc et Madagascar</p>
      <p>Transfert de données hors UE (OUI/NON) : OUI</p>
      <p>Residor s’engage à s’assurer que le Sous-traitant ultérieur soit soumis aux mêmes obligations eu égard
        à la
        protection des données à caractère personne que celles qui sont imposées dans le cadre de la présente
        Annexe.
        Hostfnly demeure responsable vis-à-vis du Bailleur en cas de défaillance du Sous-traitant ultérieur.</p>
      <p>Chacune des Parties s’engage à ne traiter et stocker les données à caractère personnel que dans le
        territoire
        de l’Union européenne et/ou conformément au présent article. Par exception à ce qui précède, les Parties
        sont
        autorisées à effectuer un transfert des données à caractère personnel en dehors du territoire de l’Union
        européenne à la condition de mettre en place des garanties appropriées encadrant ledit transfert au sens
        de la
        Réglementation applicable.</p>
      <p>Chacune des Parties doit notifier l’autre Partie de toute violation de données à caractère personnel au
        sens
        de
        la Réglementation applicable dans les meilleurs délais. Cette notification doit inclure les informations
        demandées par l’article 33 du RGPD. Chacune des Parties est habilitée, en tant que telle, à décider et
        procéder
        aux notifications qui s’imposeraient, par tout moyen de son choix, auprès de l’autorité de contrôle et
        des
        personnes concernées. Toutefois, les Parties s’engagent à se concerter en urgence au préalable afin de
        convenir
        de la teneur de la notification à effectuer. Dans le cas d’une violation de Données à caractère
        personnel, la
        Partie concernée (c’est-à-dire la Partie sur le système d’information de laquelle la violation est
        advenue)
        doit, dans les meilleurs délais et en coordination avec l’autre Partie, prendre les mesures nécessaires
        en
        vertu
        de la Réglementation applicable et des normes techniques afin de remédier à la violation.</p>
      <p>Les Parties conservent les données à caractère personnel pendant la durée définie dans le cadre de la
        présente
        Annexe. Chaque Partie procède à la suppression des données à caractère personnel (i) lorsque la durée
        définie
        dans la présente Annexe parvient à échéance, (ii) sur demande expresse de l’autre Partie ou (iii) sur
        demande
        documentée d’une personne concernée.</p>
    </div>
  </div>
  <Footer/>
</template>

<script>
import NavBar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Conditions",
  components: {Footer, NavBar}
}
</script>

<style scoped>
.conditions-utilisation {
  font-family: Arial, sans-serif;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

h2, h3, h4 {
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}
</style>
