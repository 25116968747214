<template>
  <section class="section bg-light text-white py-5">
    <div class="container-fluid col-md-11">
      <div class="card">
        <div class="card-body d-md-flex">
          <!-- Colonne d'image (pour les grands écrans) -->
          <div class="col-md-4">
            <img
                src="https://medias.paris2024.org/uploads/2022/11/phryge2.png?x-oss-process=image/resize,w_800,h_800,m_lfit/format,png"
                class="img-fluid" alt="...">
          </div>

          <!-- Colonne de texte -->
          <div class="col-md-8">
            <h1 class="display-4">15 MILLIONS !</h1>
            <p class="subtitle text-dark">C’est le nombre de visiteurs attendus pendant les Jeux Olympiques ! <br><br>Une
              opportunité rêvée pour les propriétaires qui veulent mettre en location leur appartement.
              <br>Tu as envie toi aussi de faire partie de l’aventure ?<br><br> Nos équipes sont là pour répondre à toutes vos
              questions.</p>
            <router-link to="/services">
              <button class="btn btn-outline-success custom-btn">En savoir plus sur Résidor</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Satisfaction"
}
</script>

<style scoped>
.display-4 {
  font-weight: 600;
}

.subtitle {
  font-size: 18px;
  font-weight: 400;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  font-weight: 300;
  opacity: 75%;
}

.card {
  border: none;
  background-color: #FC9063;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-radius: 25px;
}

section {
  margin-bottom: 2rem;
}

.custom-btn {
  color: white;
  border-color: white;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.custom-btn:hover {
  background-color: white;
  color: #FC9063;
}

img {
  max-height: 300px;
}
</style>
