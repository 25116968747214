<template>
  <div class="sejour">
    <div class="container-fluid col-md-10 pb-5 pt-5">
      <h1 class="display-4">Trouvez votre séjour idéal</h1>
      <p class="subtitle">Découvrez nos appartements au centre de Paris</p>
    </div>

    <div class="container-fluid col-md-11">
      <!-- Filtres -->
      <div class="row justify-content-center">
        <div class="col-md-4 col-sm-5 mb-4">
          <div class="filter">
            <label for="nbpieces-select">Filtrer par nombre de pièces :</label>
            <select id="nbpieces-select" v-model="selectedNbpieces" class="form-select">
              <option value="">Tous les nombres de pièces</option>
              <option v-for="nbpieces in sortedNbpieces" :key="nbpieces" :value="nbpieces">{{ nbpieces }} pièces
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4 col-sm-5 mb-4">
          <div class="filter">
            <label for="arrondissement-select">Filtrer par arrondissement :</label>
            <select id="arrondissement-select" v-model="selectedArrondissement" class="form-select">
              <option value="">Tous les arrondissements</option>
              <option v-for="arrondissement in sortedArrondissements" :key="arrondissement" :value="arrondissement">
                {{ arrondissement === 'Hors Paris' ? 'Hors Paris' : 'Arrondissement n°' + arrondissement }}
              </option>
            </select>
          </div>

        </div>
        <div class="col-md-4 col-sm-5 mb-4">
          <div class="filter">
            <label for="chambres-select">Filtrer par nombre de chambres :</label>
            <select id="chambres-select" v-model="selectedChambres" class="form-select">
              <option value="">Toutes les chambres</option>
              <option v-for="chambres in sortedChambres" :key="chambres" :value="chambres">{{ chambres }} chambres
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-3" v-for="logement in displayedLogements" :key="logement.id">
          <div class="mb-5">
            <div class="mb-5">
              <router-link :to="{ name: 'logement-details', params: { id: logement.id } }">
                <!-- Utilisation du carrousel Bootstrap avec un identifiant unique -->
                <div :id="'carouselExample_' + logement.id" class="carousel slide">
                  <!-- Réglez la durée du slide en millisecondes (3000 ms = 3 secondes) -->
                  <div class="carousel-inner">
                    <template v-for="(photo, photoIndex) in logementPhotosMap[logement.id].slice(0, 4)"
                              :key="photoIndex">
                      <div :class="{ 'carousel-item': true, active: photoIndex === 0 }">
                        <img :src="getPhotoUrl(photo.imageName)" class="d-block card-img-top mb-2 rounded-square"
                             :alt="photo.imageName">
                      </div>
                    </template>
                  </div>
                  <a class="carousel-control-prev ml-2" :href="'#carouselExample_' + logement.id" role="button"
                     data-bs-slide="prev">
                    <i class="custom-icon custom-prev-icon"></i>
                  </a>
                  <a class="carousel-control-next mr-2" :href="'#carouselExample_' + logement.id" role="button"
                     data-bs-slide="next">
                    <i class="custom-icon custom-next-icon"></i>
                  </a>
                </div>
              </router-link>
              <div class="card-body card-wrapper">
                <div>
                  <p class="font-weight-bold">{{ logement.titre }}</p>
                  <p class="small">{{ logement.description }}</p>
                  <p class="mt-1">A partir de <span class="font-weight-bold">{{ logement.prixmoyen }}€</span> par nuit
                  </p>
                </div>
                <h5>
                  <span class="badge bg-perso p-2 text-white align-items-end">
                    <i class="fa fa-star mr-2"></i>
                    {{ logement.rating }}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid col-md-11">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <h5>Poursuivez l'exploration des Résidor</h5>
            <button @click="loadMoreLogements" class="btn custom-btn">Afficher plus</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, computed} from 'vue';
import axios from 'axios';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Listing',
  setup() {
    const logements = ref([]);
    const logementPhotosMap = ref({});
    const isLoading = ref(true);
    const logementsPerPage = ref(8);
    const selectedNbpieces = ref('');
    const selectedChambres = ref('');
    const selectedArrondissement = ref('');

    const nbpieces = computed(() => {
      return [...new Set(logements.value.map(logement => logement.nbpieces))];
    });

    const sortedNbpieces = computed(() => {
      return nbpieces.value.slice().sort((a, b) => a - b);
    });

    const chambres = computed(() => {
      return [...new Set(logements.value.map(logement => logement.chambres))];
    });

    const sortedChambres = computed(() => {
      return chambres.value.slice().sort((a, b) => a - b);
    });

    const arrondissements = computed(() => {
      return [...new Set(logements.value.map(logement => logement.arrondissement))];
    });

    const sortedArrondissements = computed(() => {
      return arrondissements.value.slice().sort((a, b) => {
        // Convertir les arrondissements en nombres pour le tri numérique
        const numericA = parseInt(a, 10);
        const numericB = parseInt(b, 10);

        if (!isNaN(numericA) && !isNaN(numericB)) {
          return numericA - numericB;
        }

        // Si l'un des arrondissements n'est pas un nombre, utilisez la comparaison de chaînes
        return a.localeCompare(b);
      });
    });

    onMounted(async () => {
      try {
        await fetchLogements();
        await fetchPhotos();
        isLoading.value = false;
      } catch (error) {
        console.error('An error occurred:', error);
      }
    });

    const fetchLogements = async () => {
      try {
        const response = await axios.get('https://api-residor.residor-paris.com/api/logements');
        logements.value = response.data['hydra:member'].map((logement) => ({
          id: logement.id,
          titre: logement.titre,
          description: logement.description,
          rating: logement.rating,
          prixmoyen: logement.prixmoyen,
          capacity: logement.capacity,
          chambres: logement.chambres,
          lits: logement.lits,
          salledebains: logement.salledebains,
          arrondissement: logement.arrondissements,
          nbpieces: logement.nbpieces,
        }));
      } catch (error) {
        console.error('Erreur lors de la récupération des logements', error);
      }
    };

    const fetchPhotos = async () => {
      try {
        const response = await axios.get('https://api-residor.residor-paris.com/api/photos');
        const photosData = response.data['hydra:member'];

        photosData.forEach((photo) => {
          const logementId = extractIdFromUrl(photo.id_logements);
          if (!logementPhotosMap.value[logementId]) {
            logementPhotosMap.value[logementId] = [];
          }
          logementPhotosMap.value[logementId].push({
            imageName: photo.imageName,
          });
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des images', error);
      }
    };

    function extractIdFromUrl(url) {
      const parts = url.split('/');
      return parseInt(parts[parts.length - 1], 10);
    }

    const getPhotoUrl = (imageName) => {
      return `https://api-residor.residor-paris.com/images/photos/${imageName}`;
    };

    const logementsWithPhotos = computed(() => {
      return logements.value.filter(
          (logement) =>
              logementPhotosMap.value[logement.id] &&
              logementPhotosMap.value[logement.id].length > 0
      );
    });

    const displayedLogements = computed(() => {
      return logementsWithPhotos.value.filter((logement) => {
        const matchesNbpieces = !selectedNbpieces.value || logement.nbpieces === selectedNbpieces.value;
        const matchesChambres = !selectedChambres.value || logement.chambres === selectedChambres.value;
        const matchesArrondissement = !selectedArrondissement.value || logement.arrondissement === selectedArrondissement.value;

        return matchesNbpieces && matchesChambres && matchesArrondissement;
      }).slice(0, logementsPerPage.value);
    });


    const loadMoreLogements = () => {
      logementsPerPage.value += 8;
    };

    return {
      displayedLogements,
      logementPhotosMap,
      getPhotoUrl,
      isLoading,
      loadMoreLogements,
      selectedNbpieces,
      sortedNbpieces,
      selectedChambres,
      sortedChambres,
      selectedArrondissement,
      sortedArrondissements,
    };
  },
};
</script>


<style scoped>
.display-4 {
  font-weight: 700;
}

.subtitle {
  font-size: 18px;
  font-weight: 300;
}

.sejour {
  margin-bottom: 2rem;
  padding-bottom: 3rem;
}

.card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-img-top {
  border-radius: 15px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

h5 {
  font-family: Helvetica, sans-serif;
}

p {
  font-family: Helvetica, sans-serif;
  margin-bottom: 0px;
}

.rounded-square {
  border-radius: 15px;
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.bg-perso {
  background-color: rgb(75, 108, 204);
}

.carousel-control-prev,
.carousel-control-next {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  color: black;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.custom-icon {
  font-family: Arial, sans-serif;
}

.custom-prev-icon::before {
  content: "←";
}

.custom-next-icon::before {
  content: "→";
}

.carousel-control-prev,
.carousel-control-next {
  top: calc(50% - 20px);
}

.custom-btn {
  color: #FC9063;
  border-color: #FC9063;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  margin: 1rem;
}

.custom-btn:hover {
  background-color: #FC9063;
  color: white;
  border-color: white;
}
</style>
