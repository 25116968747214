<template>
  <section class="section bg-light text-dark py-5">
    <div class="container">
      <div class="text-center">
        <h1 class="display-4">Ce que nos propriétaires disent</h1>
        <p class="subtitle">Chez Résidor, nous faisons tout pour rendre chaque séjour agréable</p>
      </div>
      <div class="row">
        <div v-for="review in reviews" :key="review.id" class="col-md-4 mb-4">
          <div class="card">
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p>{{ review.text }}</p>
                <footer class="blockquote-footer">{{ review.name }}, <cite title="Source Title">{{ review.date }}</cite></footer>
                <div class="etoiles">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { defineComponent } from 'vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Avis',
  data() {
    return {
      reviews: [
        {
          id: 1,
          name: "Rudy",
          text: "J’ai entendu parler comme tout le monde de la forte tension du marché immobilier pendant la période des Jeux Olympiques 2024. Une vraie opportunité.",
          date: "Paris 18ème, T4",
        },
        {
          id: 2,
          name: "Delphine",
          text: "L’équipe a su me rassurer et s’adapter à mes besoins et permis de garder une pièce fermée pour stocker toutes nos affaires. Je peux partir l’esprit tranquille et profiter de mes vacances avec mes enfants",
          date: "Paris 15ème, T4",
        },
        {
          id: 3,
          name: "Maryvonne",
          text: "Grâce à Résidor je n’ai plus aucune inquiétude. Ils s’occupent de toute la gestion de mes affaires, du linge et me garantissent de bons revenus.",
          date: "Paris 19ème, T6",
        },
      ],
    };
  },
});
</script>

<style scoped>
.display-4 {
  font-weight: 600;
}

.subtitle {
  font-size: 18px;
  font-weight: 300;
}

.card {
  border: none;
  border-radius: 10px;
}

section {
  margin-bottom: 2rem;
}
</style>
