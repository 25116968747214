<template>
  <NavBar/>
  <QuiSommesNous/>
  <Formulaire/>
  <Satisfaction/>
  <Footer/>
</template>

<script>
import NavBar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Formulaire from "@/pages/Formulaire";
import Satisfaction from "@/components/Satisfaction";
import QuiSommesNous from "@/components/QSN/Qui-sommes-nous";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Proprietaire",
  components: {QuiSommesNous, Satisfaction, Formulaire, Footer, NavBar}
}
</script>

<style scoped>

</style>