<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4" v-for="(icon, index) in icons" :key="index">
          <div class="text-left mb-3">
            <hr class="d-md-none"> <!-- Ligne de séparation en version mobile -->
            <span class="badge">
              <i :class="icon" class="mr-2 custom-icon"></i>
              <span class="text-black">{{ equipmentDescriptions[index] }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Modal",
  data() {
    return {
      icons: [
        "fas fa-wifi",
        "fas fa-coffee",
        "fas fa-bed",
        "fas fa-elevator",
        "fas fa-bicycle",
        "fas fa-kitchen-set",
      ],
      equipmentDescriptions: [
        "Accès Wi-Fi",
        "Machine à café",
        "Chambre avec lit double",
        "Ascenseur",
        "Espaces pour vélos",
        "Cuisine équipée",
      ],
    };
  },
};
</script>

<style scoped>
.custom-icon {
  font-size: 2rem;
  color: rgb(75, 108, 204);
}

/* Ajoutez d'autres styles CSS personnalisés si nécessaire */

@media (max-width: 767px) {
  .text-center {
    text-align: center;
  }
}
</style>
