<template>
  <div class="bg-img">
    <div class="container d-flex flex-column justify-content-center align-items-center text-center">
      <h1 class="text-white display-1">Trouvez votre logement idéal</h1>
      <router-link to="/logements" class="btn custom-btn col-12 col-md-6">Explorer nos Résidor</router-link>
    </div>
  </div>
</template>


<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Search",
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

.container {
  height: 100vh;
}

h1 {
  font-weight: 700;
}

.bg-img {
  background-image: url(../../public/appartements/appartsearch.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.custom-btn {
  background-color: #FC9063;
  color: white;
  border-color: #FC9063;
  font-size: 1.5rem;
  font-weight: bold;
  border-width: 3px;
  margin: 1rem;
}

</style>