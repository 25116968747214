<template>
  <section class="section mt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="">
          <h1 class="display-4 text-center">Prendre rendez-vous</h1>
          <!-- Début de widget en ligne Calendly -->
          <div class="calendly-inline-widget" data-url="https://calendly.com/guillaume-8zi/rendez-vous-avec-residor-clone?month=2024-02" style="min-width:320px;height:700px;"></div><!-- Fin de widget en ligne Calendly -->
        </div>
        <div class="col-md-6">
          <!-- Contenu pour la deuxième colonne (si nécessaire) -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Formulaire",
  mounted() {
    // Initialiser le widget Calendly
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = false;
    document.body.appendChild(script);
  }
}

</script>



<style scoped>

.custom-btn {
  color: #FC9063;
  border-color: #FC9063;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  margin: 1rem;
}

.custom-btn:hover {
  background-color: #FC9063;
  color: white;
  border-color: white;
}

input:focus {
  border: solid 2px #FC9063;
  outline: none;
  box-shadow: none;
}

textarea:focus {
  border: solid 2px #FC9063;
  outline: none;
  box-shadow: none;
}

textarea {
  resize: both;
  height: 100px;
}

</style>