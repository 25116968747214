<template>
  <section class="bg-custom text-white py-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="display-4 title">Résidor est une entreprise dynamique et experte dans le secteur hôtelier.</h1>
        </div>
        <div class="col-md-6">
          <p class="lead">Heureux d’accompagner les propriétaires Parisiens pour leur permettre de tirer profit de l’évènement
            exceptionnel que représentent les Jeux Olympiques.<br><br> Nous leur avons préparé une formule sur mesure pour
            répondre à la forte demande de location pendant cette période.</p>
        </div>
      </div>
    </div>

    <div class="container text-center my-5">
      <div class="row">
        <div class="col-md-12">
          <img src="https://i.ibb.co/JrFVbML/photo-equipe-residor.jpg" class="img-fluid rounded-circle"
               alt="Équipe Résidor">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Qui-sommes-nous"
}
</script>

<style scoped>

.bg-custom {
  background-color: #FC9063;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
</style>
